import React from 'react';
import { useDispatch } from 'react-redux';
import { addExperienceSummary } from '../../../../commonRedux/scan/action';

export default function useWorkExperiences() {
  const dispatch = useDispatch();
  const data = [
    {
      title: 'New Graduate',
      experience: '0',
      link: '/questionnaire/highest_education',
    },
    {
      title: 'Junior',
      experience: '1-3',
      link: '/questionnaire/highest_education',
    },
    {
      title: 'Mid-Level',
      experience: '3-5',
      link: '/questionnaire/highest_education',
    },
    {
      title: 'Senior',
      experience: '6+',
      link: '/questionnaire/highest_education',
    },
  ];

  // const summariesData = [
  //   {
  //     id: 'None',
  //     summaries:
  //       'Recent college graduate with a Bachelor degree in Human Resources Management.| Possesses an understanding of best practices in HR, and has excellent organizational, communication, and problem-solving skills.| Eager to apply knowledge and experience with a team-oriented and results-driven organization. | Ambitious and highly motivated recent graduate with a Bachelor degree in Human Resources Management. | Possesses strong interpersonal, written and verbal communication, and analytical skills.| Proven ability to coordinate and manage multiple tasks and prioritize competing demands.| Looking to leverage expertise to provide full-scope HR services in a fast-paced environment.| Recent college graduate with a Bachelor degree in Human Resources Management. Skilled at developing and maintaining relationships with both internal and external stakeholders. | Experience in recruitment, onboarding, and employee relations. |Demonstrated success in streamlining processes and using technology to increase efficiency.Driven recent graduate with a Bachelor degree in Human Resources Management. Proven ability to provide effective operational and tactical HR support. Experienced in developing and executing HR policies, procedures, and standards. | Excellent people management, problem-solving, and organizational skills.|Recent college graduate with a Bachelor degree in Human Resources Management. Experienced in HR processes such as compensation and benefits, employee relations, and performance management. Demonstrated skills in team building, conflict resolution, and fostering a positive work environment. | Seeking to leverage expertise in a dynamic and challenging environment.',
  //   },
  //   {
  //     id: 'Less than 5 years',
  //     summaries:
  //       'Accomplished Human Resources Manager with 3 years of experience in resolving conflicts, managing payroll, and recruiting new employees.| Skilled in developing strong relationships with employees, creating and implementing performance improvement plans, and developing and executing HR strategies. |Experienced Human Resources Manager with a proven track record of successfully managing payroll, recruiting new employees, and resolving conflicts. | Adept in creating and implementing performance improvement plans, developing and executing HR strategies, and developing strong relationships with employees. |Human Resources Manager with 4 years of experience in resolving conflicts, managing payroll, and recruiting new employees. | Skilled in developing and executing HR strategies, creating and implementing performance improvement plans, and developing strong relationships with employees.|Results-oriented Human Resources Manager with 6 years of experience in managing payroll, recruiting new employees, and resolving conflicts. | Demonstrated ability to create and implement performance improvement plans, develop and execute HR strategies, and create strong relationships with employees. | Efficient Human Resources Manager with 5 years of experience in managing payroll, recruiting new employees, and resolving conflicts. | Adept in creating and implementing performance improvement plans, developing and executing HR strategies, and developing strong relationships with employees.',
  //   },
  //   {
  //     id: '5+ years',
  //     summaries:
  //       'Highly accomplished Human Resources Manager with 10 years of experience in resolving conflicts, managing payroll, and recruiting new employees. | Skilled in developing strong relationships with employees, creating and implementing performance improvement plans, and developing and executing HR strategies that have resulted in a 25% increase in employee satisfaction. | Expert Human Resources Manager with 15 years of experience in recruiting new employees, managing payroll, and resolving conflicts. | Adept in developing and executing HR strategies that have improved employee satisfaction by 30%, creating and implementing performance improvement plans, and developing strong relationships with employees. | Proven Human Resources Manager with 14 years of experience in managing payroll, recruiting new employees, and resolving conflicts. | Demonstrated ability to create and implement performance improvement plans that have improved employee satisfaction by 50%, develop and execute HR strategies, and create strong relationships with employees. | Successful Human Resources Manager with 12 years of experience in recruiting new employees, managing payroll, and resolving conflicts. | Adept in creating and implementing performance improvement plans that have resulted in a 45% increase in employee satisfaction, developing and executing HR strategies, and developing strong relationships with employees. | Strategic Human Resources Manager with 11 years of experience in resolving conflicts, managing payroll, and recruiting new employees. | Skilled in developing and executing HR strategies that have improved employee satisfaction by 35%, creating and implementing performance improvement plans, and developing strong relationships with employees. ',
  //   },
  // ];

  const setsummaryHandler = (title,index) => {
    // const filteredSummary =
    //   summariesData?.find((item) => item?.id === id) || {};

    dispatch(addExperienceSummary('Human Resources Managers', title));
    localStorage.setItem('exp', title);
    localStorage.setItem('experienceCardIndex', index);
    localStorage.setItem('builderVisited', 'visited');
  };

  return { data, setsummaryHandler };
}
