import React from 'react';
import { Grid } from '@material-ui/core';
import { templateList } from '../../helper/templates';
import SelectionCard from '../../component/Selectioncards/SelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  addExperienceSummary,
  getSuggestions,
} from '../../../../commonRedux/scan/action';
import {
  addNewSkillObj,
  deleteObjInArray,
  delField,
  delField2,
  onAddSummaryHandler,
  onBlurField,
  onBlurField1,
  onBlurField2,
  onBlurFieldWithShift,
} from '../../redux/actions';
import InfoIcon from '../../../../commonComponents/InfoIcon';
import { tipsList } from '../constants/protips';
import { colors } from '../../../../commonComponents/colors';

export default function StepsContainer({ children, classes, step, data }) {
  // let { currentTemplate } = useSelector((state) => state.editorReducer);
  const { accordion: expanded, resumeData } = useSelector(
    (state) => state.editorReducer,
  );
  const dataKeysSkill = data?.map((item) => item?.name);
  const dataKeysWork = data?.map((item) => item?.summary);
  const dataKeysassociations = data?.map((item) => item?.Name);
  const dataKeysadditional = data?.map((item) => item?.Name);
  const dataKeysCerts = data?.map((item) => item?.summary);
  const dispatch = useDispatch();
  const { summarySuggestion, builderSelectedTitle } = useSelector(
    (state) => state?.fileScanReducer,
  );

  React.useEffect(() => {
    let exp = localStorage.getItem('exp');
    if (exp && builderSelectedTitle) {
      dispatch(addExperienceSummary(builderSelectedTitle, exp));
      dispatch(getSuggestions(builderSelectedTitle));
    } else {
      dispatch(addExperienceSummary('Human Resources Managers', '0'));
      dispatch(getSuggestions('Human Resources Managers'));
    }
  }, [builderSelectedTitle]);

  const store =
    useSelector((state) => state?.fileScanReducer?.suggestions) || [];
  const thumbnail = () => {
    let thumb = templateList.filter((item) => item.id === 'resumeThumb');
    return thumb[0].image;
  };
  const pathSummary = `objective.body`;
  const _onAddSummaryHandler = (item) => {
    dispatch(onBlurField2(`<li>${item}</li> \n`, pathSummary));
  };
  let summary = resumeData?.objective?.body;
  const _onAddOrDelSummaryHandler = (item) => {
    if (summary) {
      let originalString = summary.replace(/&#39;/g, "'");
      if (originalString) {
        summary = originalString;
      }
      let summaryExist = originalString?.includes(item);
      if (summaryExist) {
        const WithTag = `<li>${item}</li>`;
        let newSummary = originalString?.replace(WithTag, '');
        dispatch(onAddSummaryHandler(newSummary));
      } else {
        let getListItem = originalString?.trim()?.slice(4, -5);
        let updateExistingString = `<ul>${getListItem}<li>${item}</li></ul>`;
        dispatch(onAddSummaryHandler(updateExistingString));
      }
    } else {
      let newList = `<ul><li>${item}</li></ul>`;
      dispatch(onAddSummaryHandler(newList));
    }
  };
  const _removeItemSummary = (item) => {
    dispatch(delField2(item, pathSummary));
  };
  const _addOrDelSuggestedItemSummary = (item) => {
    if (data.join().includes(item)) {
      _removeItemSummary(item);
    } else {
      _onAddSummaryHandler(item);
    }
  };

  const path = 'skills.items';
  const _addSuggestedItem = (item) => {
    const newSkill = {
      id: item,
      name: item,
    };
    dispatch(addNewSkillObj(newSkill, path));
  };
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const _addOrDelSuggestedItem = (item) => {
    if (dataKeysSkill?.includes(item)) {
      _removeItem(dataKeysSkill?.indexOf(item));
    } else {
      _addSuggestedItem(item);
    }
  };
  //skills functions end

  //experience dunction start
  const path1 = 'work.items';
  const _addNewItem = (item) => {
    dispatch(
      onBlurField1(
        `<li>${item}</li> \n`,
        `${path1}.${expanded}.summary`,
        'Experience',
        expanded,
      ),
    );
  };
  const _removeItem1 = (item) => {
    let deletedPath = `${path1}.${expanded}.summary`;
    dispatch(delField(item, deletedPath, 'Experience', expanded));
  };
  const _addOrDelSuggestedItem1 = (item) => {
    if (dataKeysWork[expanded]?.toLowerCase().includes(item?.toLowerCase())) {
      _removeItem1(item);
    } else {
      _addNewItem(item);
    }
  };

  //experience dunction start
  const path4 = 'certifications.items';
  const _addNewItem3 = (item) => {
    dispatch(
      onBlurField1(
        `<li>${item}</li> \n`,
        `${path4}.${expanded}.summary`,
        'Certifications',
        expanded,
      ),
    );
  };
  const _removeItem3 = (item) => {
    let deletedPath = `${path4}.${expanded}.summary`;
    dispatch(delField(item, deletedPath, 'Certifications', expanded));
  };
  const _addOrDelSuggestedItem3 = (item) => {
    if (dataKeysCerts[expanded]?.includes(item)) {
      _removeItem3(item);
    } else {
      _addNewItem3(item);
    }
  };
  // console.log(dataKeysCerts.length)
  //   const _addNewItem3 = (item, index) => {
  //     dispatch(onBlurField(item, `${path4}.${dataKeysCerts.length}.title`));
  //   };
  //   const _removeItem3 = (index) => {
  //     let deletedPath = `${path4}.${index}`;
  //     dispatch(deleteObjInArray(deletedPath));
  //   };
  //   const _addOrDelSuggestedItem3 = (item, index) => {
  //     if (dataKeysCerts?.includes(item)) {
  //       _removeItem3(dataKeysCerts?.indexOf(item));
  //     } else {
  //       _addNewItem3(item, index);
  //     }
  //   };

  //experience functions end

  //associations

  const path2 = 'associations.items';

  const _addNewItem2 = (item) => {
    dispatch(onBlurFieldWithShift(item, `${path2}.${0}.Name`, path2));
  };
  const _removeItem2 = (index) => {
    let deletedPath = `${path2}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const _addOrDelSuggestedItem2 = (item, index) => {
    if (dataKeysassociations?.includes(item)) {
      _removeItem2(dataKeysassociations?.indexOf(item));
    } else {
      _addNewItem2(item, index);
    }
  };

  const path5 = 'additional_skills.items';

  const _addNewItem4 = (item) => {
    dispatch(onBlurFieldWithShift(item, `${path5}.${0}.Name`, path5));
  };
  const _removeItem4 = (index) => {
    let deletedPath = `${path5}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const _addOrDelSuggestedItem4 = (item, index) => {
    if (dataKeysadditional?.includes(item)) {
      _removeItem4(dataKeysadditional?.indexOf(item));
    } else {
      _addNewItem4(item, index);
    }
  };

  return (
    <>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item sm={12} md={7} lg={8} xl={8}>
          {children}
        </Grid>
        <Grid item md={5} lg={4} xl={4}>
          {step === 4 ||
          step === 1 ||
          step === 3 ||
          step === 7 ||
          step === 17 ||
          (step === 5 && summarySuggestion) ? (
            <SelectionCard
              suggestions={
                step === 3
                  ? {
                      store: store?.['generalSkills'],
                      title: 'Skills',
                      onClick: _addOrDelSuggestedItem,
                      data: dataKeysSkill?.length > 0 ? dataKeysSkill : [],
                    }
                  : step === 1
                  ? {
                      store: store?.['experience'],
                      title: 'Experience',
                      onClick: _addOrDelSuggestedItem1,
                      data: dataKeysWork?.length > 0 ? dataKeysWork : [],
                      expanded: expanded,
                    }
                  : step === 5
                  ? {
                      store: store?.['certifications'],
                      title: 'Certifications',
                      onClick: _addOrDelSuggestedItem3,
                      data: dataKeysCerts?.length > 0 ? dataKeysCerts : [],
                      expanded: expanded,
                    }
                  : step === 7
                  ? {
                      store: store?.['associations'],
                      title: 'Associations',
                      onClick: _addOrDelSuggestedItem2,
                      data:
                        dataKeysassociations?.length > 0
                          ? dataKeysassociations
                          : [],
                    }
                  : step === 17
                  ? {
                      store: store?.['softwareSkills'],
                      title: 'Additional Skills',
                      onClick: _addOrDelSuggestedItem4,
                      data:
                        dataKeysadditional?.length > 0
                          ? dataKeysadditional
                          : [],
                    }
                  : step === 4
                  ? {
                      store: summarySuggestion,
                      title: 'Summary',
                      onClick: _onAddOrDelSummaryHandler,
                      data: summary?.includes('&#39;')
                        ? summary?.replace(/&#39;/g, "'")
                        : summary,
                    }
                  : ''
              }
            />
          ) : (
            <div
              style={{
                width: '100%',
                border: '1px solid #ccc',
                padding: '10px 10px 10px',
                // minHeight: '300px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '-10px',
                }}
              >
                <InfoIcon width={80} />
              </div>
              <div
                style={{
                  padding: '20px 20px 5px',
                  boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.2)',
                  minHeight: 'calc(250px - 15px)',
                  borderRadius: '8px',
                }}
              >
                {tipsList[step]?.length
                  ? tipsList[step]?.map((tip, index) => (
                      <div key={index}>
                        <p
                          style={{
                            marginBottom: '10px',
                            lineHeight: '1rem',
                            fontSize: '14px',
                          }}
                        >
                          <span
                            style={{
                              color: colors.blue,
                              fontWeight: 'bold',
                              fontSize: '13px',
                            }}
                          >
                            ⦁{' '}
                          </span>
                          {tip}
                        </p>
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
